import React from "react"
import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import rehypeReact from 'rehype-react'

import { MainWrapper } from '../components/Wrapper'
import { Container, SiteGrid } from '../components/Container'
import Header from "../components/Header"
import Footer from "../components/Footer"
import { SiteAnchor } from "../components/Link"
import { SectionHeader, BodyText, BlockHeading, BlockContentParagraph, BlockContentUl, BlockContentOl, LiText } from "../components/Type"
import { color, space, media } from "../styles/constants"

export const query = graphql`
    query(
        $slug: String!
    ){
        markdownRemark(
        fields:{
            slug:{
            eq: $slug
            }
        }
        ){
        frontmatter{
            title
            lastUpdatedDate
        }
        htmlAst
        }
        site{
            siteMetadata{
                pageTitleSuffix
            }
        }
    }
`
// 0
const CondensedSiteGrid = styled(SiteGrid)`
    grid-row-gap: 8vh;
`
// 0 > 1
const ContentArea = styled(Container)`
`
// 1 > 1
const FrontMatterArea = styled.div`
    text-align: center;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}
`
// 1 > 2
const MdBodyArea = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}
    // 1 > 2 > 1
    > div{
        display: grid;
        ${media.xs`
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: ${space.p150};
        `}
        ${media.md`
            grid-template-columns: repeat(8, 1fr);
            grid-column-gap: ${space.p200};
        `}
        ${media.xl`
            grid-template-columns: repeat(12, 1fr);
        `}
    }
`

const DateText = styled(BodyText)`
    color: ${color.lightGrey};
    opacity: 0.5;
    text-align: center;
    margin-top: ${space.p50};
`

const PageHeader = styled(SectionHeader)`
`

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        "a": SiteAnchor,
        "p": BlockContentParagraph,
        "ol": BlockContentOl,
        "ul": BlockContentUl,
        "h2": BlockHeading,
        "li": LiText
     },
  }).Compiler;

const MdTemplatePage = (props) => {
    const pageTitle = props.data.markdownRemark.frontmatter.title + props.data.site.siteMetadata.pageTitleSuffix
    return (
        <Layout title={pageTitle}>
            <MainWrapper />
            <CondensedSiteGrid>
                <Header />
                <ContentArea>
                    <FrontMatterArea>
                        <PageHeader>{props.data.markdownRemark.frontmatter.title}</PageHeader>
                        { props.data.markdownRemark.frontmatter.lastUpdatedDate &&
                            <DateText><strong>Last updated: </strong>{props.data.markdownRemark.frontmatter.lastUpdatedDate}</DateText>
                        } 
                    </FrontMatterArea>
                    <MdBodyArea>
                        {
                        renderAst(props.data.markdownRemark.htmlAst)
                        }
                    </MdBodyArea>
                </ContentArea>
                <Footer />
            </CondensedSiteGrid>
        </Layout>
    )
}

export default MdTemplatePage